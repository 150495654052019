body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00b7ff;
}
.todo-title {
  cursor: pointer;
}
.completed-todo {
  text-decoration: line-through;
}
.tab-list > span {
  padding: 5px 8px;
  border: 1px solid #282c34;
  border-radius: 10px;
  margin-right: 5px;
  cursor: pointer;
}
.tab-list > span.active {
  background-color: #282c34;
  color: #ffffff;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.active {
  background-color: #0099FF;
}


.canvas-min canvas, .canvas-min .react-pdf__Page__svg {
  min-width: 730px;
  min-height: 1030px;
  overflow: visible;
}
.canvas-max canvas, .canvas-max .react-pdf__Page__svg {
  min-width: 970px;
  min-height: 1290px;
  overflow: visible;
}
.react-pdf__Page__svg {
  overflow: visible ;
}
.konvajs-content {
  width: 100%;
}
.right-panel {
  z-index: 3;
}
#container {
  position: absolute;
  z-index: 1;
}
.tab-content .active {
  background-color: transparent;
}




.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-content {
  display: block;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  max-height: 200px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}


.config-wrap {
  display: none;
}
.modal .modal-content {
  border: 0;
}
.header-modal {
  background-color: #4e73df;
}
.modal .header-modal .close {
  color: #fff;
  opacity: 1;
}
.modal-open .modal.show {
  overflow-x: hidden;
  overflow-y: hidden;
}
@media (min-width: 576px){
  .modal.show .modal-dialog {
    margin: 1rem auto;
  } 
}
